import { clearTexture, updateTexture } from './texture.js';
import { drawScene } from './scene.js';

let isPlayable = false;
let paused = true;

function handleLoadstartEvent() {
  isPlayable = false;
}

function handlePlayingEvent() {
  isPlayable = true;
}

const createRenderer = (gl, programInfo, buffers, texture, video, showVideo) => {
  video.addEventListener('loadstart', handleLoadstartEvent);
  video.addEventListener('playing', handlePlayingEvent);

  const render = () => {
    if (!video.ended && isPlayable !== false && !paused) {
      updateTexture(gl, texture, video);
    } else {
      clearTexture(gl, texture);
    }
    drawScene(gl, programInfo, buffers, texture);
    if (!paused) {
      window.requestAnimationFrame(render);
    }
  };

  return {
    destroy: () => {
      if (video) {
        video.removeEventListener('loadstart', handleLoadstartEvent);
        video.removeEventListener('playing', handlePlayingEvent);
      }
      isPlayable = false;
      paused = true;
    },
    start: () => {
      if (paused) {
        paused = false;
        window.requestAnimationFrame(render);
      }
    },
    stop: () => {
      paused = true;
    },
  };
};

export { createRenderer };
