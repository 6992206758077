import React from 'react';
import styled from 'styled-components';
import { CategoryActionItem, CategoryItemsList, CategoryItemRow, Title } from './Slides';

const InOfficeCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InOfficeItemActions = styled.div`
  margin-left: 1.5rem;
  letter-spacing: 0.5rem;
`;

const emojiMap = {
  ':coffee:': '☕️',
  ':woman-lifting-weights:': '🏋️‍♀️',
  ':hamburger:': '🍔',
  ':video_game:': '🎮',
};

const sortOrder = [':coffee:', ':hamburger:', ':video_game:', ':woman-lifting-weights:'];

function actionEmojis(personActions) {
  return sortOrder.map(action => (personActions || '').includes(action) && emojiMap[action]).filter(Boolean);
}

function shorterName(personName) {
  const parts = personName.split(/\s+/);
  // biome-ignore lint/style/useTemplate:
  return [parts[0], ...parts.slice(1).map(part => (part.length < 4 ? part : part[0] + '.'))].join(' ');
}

export const CurrentlyInOffice = ({ data = [] }) => {
  return (
    <CategoryItemRow>
      <InOfficeCategoryContainer>
        <Title>In the office today</Title>
        <CategoryItemsList>
          {data.map((person, index) => {
            return (
              <CategoryActionItem key={`${index}${person}`}>
                <div>{shorterName(person.name)}</div>{' '}
                <InOfficeItemActions>{actionEmojis(person.actions)}</InOfficeItemActions>
              </CategoryActionItem>
            );
          })}
        </CategoryItemsList>
      </InOfficeCategoryContainer>
    </CategoryItemRow>
  );
};
