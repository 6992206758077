import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Category, CategoryItem, CategoryItemsList, PreannounceColumn, Title } from './Slides';
import { ReactComponent as Tretton37Color } from '../assets/1337_color.svg';

const StyledTretton37Logo = styled(Tretton37Color)`
  transform: translateY(-25px) scale(1.25);
  margin-right: 15px;
  width: 64px;
`;

const HumanTime = styled.div`
  color: #05e273;
  font-weight: bold;
  font-size: 80%;
  width: 90px;
`;

function humanTime(date) {
  const days = moment(date).diff(moment(), 'days');
  const hours = moment(date).diff(moment(), 'hours');
  const showDays = days > 1;
  return showDays ? `${days} days` : `${hours}h`;
}

function renderContainer([key, list]) {
  return (
    <Category key={`${key}`}>
      <Title>{key}</Title>
      <CategoryItemsList>
        {list.map((item, index) => {
          let what = item.what;
          if (what.startsWith('tretton37')) {
            what = (
              <>
                <StyledTretton37Logo />
                {what.slice('tretton37 '.length)}
              </>
            );
          }
          return (
            <CategoryItem key={`${index}${item.what}${item.day}`}>
              <HumanTime>{humanTime(item.day)}</HumanTime> {what}
            </CategoryItem>
          );
        })}
      </CategoryItemsList>
    </Category>
  );
}

export const Preannounce = ({ data }) => {
  const entries = React.useMemo(() => {
    if (!data) return [];
    const list = Object.entries(data);
    list.sort(([a], [b]) => -a.localeCompare(b));
    return list;
  }, [data]);

  return <PreannounceColumn>{entries.map(renderContainer)}</PreannounceColumn>;
};
