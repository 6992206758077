import React from 'react';
import styled from 'styled-components';
import { useWeather } from '../hooks/use1337Api';

const WeatherInfo = styled.div`
  position: fixed;
  font-size: 45px;
  top: 19px;
  right: 40px;
`;

const WeatherIcon = styled.img`
  float: left;
  margin-top: 5px;
  margin-right: 8px;
`;

function MiniWeather({ show }) {
  const { low, temp, iconSrc } = useWeather();

  return show && low && Number.isFinite(temp) ? (
    <WeatherInfo>
      <WeatherIcon src={iconSrc} />
      {Math.round(temp)}°C
    </WeatherInfo>
  ) : (
    <></>
  );
}

export default MiniWeather;
