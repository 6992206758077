import * as actionTypes from '../constants/actionTypes';

export function setMode(mode) {
  return {
    type: actionTypes.SET_MODE,
    mode,
  };
}

export function switchEnvironment(env) {
  return {
    type: actionTypes.SWITCH_ENVIRONMENT,
    env,
  };
}

export function updateFilteredChats() {
  return {
    type: actionTypes.UPDATE_FILTERED_CHATS,
  };
}
