import moment from 'moment';
import { RssItem } from '../components/RssItem';
import { CurrentlyInOffice } from '../components/CurrentlyInOffice';
import { KnowabungaCountdown } from '../components/KnowabungaCountdown';
import { Preannounce } from '../components/Preannounce';

const KB_TYPE = 'knowabungaCountdown';
const IN_OFFICE_TYPE = 'currentlyInOffice';
const PREANNOUNCE_TYPE = 'preannounce';

const contentMap = {
  rssItem: RssItem,
  [IN_OFFICE_TYPE]: CurrentlyInOffice,
  [KB_TYPE]: KnowabungaCountdown,
  [PREANNOUNCE_TYPE]: Preannounce,
};

export const getContentComponent = type => contentMap[type] || 'div';

export function preparePeopleInOfficeSlide(data = [], index = 0) {
  return {
    id: `${IN_OFFICE_TYPE}-${index}`,
    type: IN_OFFICE_TYPE,
    data: (data || []).map(({ name, actions }) => ({ name, actions })),
  };
}

export function prepareCountdown(index = 0) {
  return {
    id: `${KB_TYPE}-${index}`,
    type: KB_TYPE,
  };
}

export function makePreannounceSlides(preannounce) {
  const topics = {
    Birthdays: [],
		"Hub events": []
  };
  for (const item of preannounce) {
    const list = topics[item.topic];
    // Collect only topics that are listed above (for now)
    if (!list) continue;
		const isFuture = moment(item.day).diff(moment(), 'hours') > 1;
		if (isFuture) list.push(item);
  }

  const chosenTopics = Object.fromEntries(Object.entries(topics).filter(([_, list]) => list.length));
  const nonEmpty = JSON.stringify(chosenTopics).length > 5;
  return nonEmpty
    ? [
        {
          id: PREANNOUNCE_TYPE,
          type: PREANNOUNCE_TYPE,
          data: chosenTopics,
        },
      ]
    : [];
}
