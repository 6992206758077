import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAuthentication } from '../hooks/useAuthentication';
import useKeydown from '../hooks/useKeydown';

import { start, stop, destroyRenderer } from '../utils/WebGL';
import {
  startMusicVisualization,
  stopMusicVisualization,
  destroyMusicVisualization,
} from '../utils/audioVisualization';
import config from '../config';

const Canvas = styled.canvas`
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const Crest = styled.img`
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -40px;
  transition: scale 0.1s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  ${props =>
    props.scale &&
    css`
      transform: scale(${1 + props.scale / 280});
    `}
`;

export default function VideoPlayer(props) {
  const { showVideo = true } = props;
  const { deviceId } = useAuthentication();
  const [videoElement] = useState(document.createElement('video'));
  const [bassLevel, setBassLevel] = useState(100);
  videoElement.crossOrigin = 'anonymous';

  useEffect(() => {
    return () => {
      videoElement.removeEventListener('playing', handlePlayingEvent, true);
      destroyRenderer();
      destroyMusicVisualization();
    };
    // ignores: handlePlayingEvent, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlePlayingEvent() {
    if (props.mode === 'visualizer') {
      stop();
      startMusicVisualization(videoElement, setBassLevel);
    } else {
      stopMusicVisualization();
      start(videoElement, showVideo);
    }
  }

  // Load and play video
  useEffect(() => {
    const srcBase = deviceId === '761b3c20-5891-11ea-8b2f-9b3d847a1596' ? 'http://localhost:3000' : config.s3Endpoint;
    videoElement.src = `${srcBase}/videos/${props.video.id}.mp4`;
    videoElement.load();
    videoElement.addEventListener('playing', handlePlayingEvent, true);

    videoElement.play().catch(err => {
      console.error('.play errored :(' + [err.name, err.message].join(' '));
      // This is getting called when there is no interaction with document
      // For our use case this isn't exactly an error since android app
      // allows media elements to play even if there is no interaction
      // TL;DR We can safely ignore the error
    });

    return () => videoElement.pause();
    // ignores: handlePlayingEvent, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video.id, deviceId]);

  const sendCurrentStatus = () => {
    props.notifyClients({
      source: 'tv',
      action: 'update',
      resource: 'playing',
      resourceIdentifier: 'info',
      object: {
        ...props.video,
        duration: videoElement.duration,
        isVideoPlaying: !videoElement.paused,
        currentTime: videoElement.currentTime,
      },
    });
  };

  useEffect(() => {
    if (props.mode === 'visualizer') {
      startMusicVisualization(videoElement, setBassLevel);
    } else {
      stopMusicVisualization(videoElement);
    }
    if (showVideo) {
      stopMusicVisualization(videoElement);
      start(videoElement, showVideo);
    } else {
      stop();
    }
    // ignores: videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode, showVideo]);

  useEffect(() => {
    if (videoElement && props.playingState) {
      const interval = setInterval(
        () => sendCurrentStatus(),
        (showVideo ? 1 : 2) * config.NOTIFY_CLIENTS_INTERVAL * 1000
      );
      sendCurrentStatus();
      return () => clearInterval(interval);
    }
    // ignores: sendCurrentStatus, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.playingState, props.video.id]);

  useEffect(() => {
    if (videoElement) {
      videoElement.addEventListener('ended', props.handleNextSong);
      return () => {
        videoElement.removeEventListener('ended', props.handleNextSong);
      };
    }
    // ignores: props.handleNextSong, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.video.id]);

  useKeydown(() => {
    if (videoElement) {
      videoElement.currentTime = 0;
    }
  }, [82, 177]); // R or ± = restart video from 0

  useEffect(() => {
    videoElement.muted = props.muted;
    // ignores: videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.muted]);

  useEffect(() => {
    setTimeout(() => {
      if (videoElement && props.playingState) {
        videoElement.play().catch(console.error);
      } else if (videoElement) {
        videoElement.pause();
      }
      sendCurrentStatus();
    }, 0);
    // ignores: sendCurrentStatus, videoElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.playingState]);

  const togglePlay = notifyClientsCallback => {
    if (videoElement?.paused) {
      videoElement.play();
    } else if (videoElement) {
      videoElement.pause();
    }
    notifyClientsCallback();
  };
  useKeydown(() => {
    togglePlay(() => {});
  }, [80, 179]); // P or ³ = toggle Pause / Play

  return (
    <>
      <Canvas show={showVideo} id="glCanvas" width="640" height="360" />
      <Canvas background show={props.mode === 'visualizer'} id="audioCanvas" width="100%" height="100%" />
      <Crest src="/_tretton37_crest_green.png" scale={bassLevel} show={props.mode === 'visualizer'} />
    </>
  );
}
