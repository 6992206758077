import React from 'react';
import styled from 'styled-components';
import { useWeather } from '../hooks/use1337Api';

const WeatherIcon = styled.img`
  padding: 0;
  margin: 0 5px 0 0;
  height: 20px;
  float: left;
`;

const WeatherInfo = styled.div`
  font-size: 11px;
  color: #0c0c91;

  svg {
    margin-bottom: -2px;
    margin-right: 3px;
  }
`;

function Weather() {
  const { city, low, high, label, iconSrc } = useWeather();

  if (!low || !Number.isFinite(low)) return null;

  return (
    <WeatherInfo>
      <WeatherIcon src={iconSrc} />
      <span>
        {city} {low}°C {low !== high && <span> - {high}°C, </span>}
        {label}
      </span>
    </WeatherInfo>
  );
}

export default Weather;
