import React from 'react';
import config from '../config';
import styled from 'styled-components';

const photosFromStream = [
  '/imagebackgrounds/gray/14252780370_91764ddf60_b.jpg',
  '/imagebackgrounds/gray/13035596294_abbcd1fe25_b.jpg',
  '/imagebackgrounds/gray/10314077315_22ef71d7be_b.jpg',
  '/imagebackgrounds/gray/14252929127_810afe804f_b.jpg',
  '/imagebackgrounds/gray/14906339147_796698e893_b.jpg',
  '/imagebackgrounds/gray/39622065425_917630f849_b.jpg',
  '/imagebackgrounds/gray/39622060895_de8ec5d7b4_b.jpg',
  '/imagebackgrounds/gray/38707061570_963714747c_b.jpg',
  '/imagebackgrounds/gray/10314193653_5c263a5070_b.jpg',
  '/imagebackgrounds/gray/15069896946_77748f4a5a_b.jpg',
  '/imagebackgrounds/gray/22090030988_f0ed16217a_b.jpg',
  '/imagebackgrounds/gray/13604836403_08058aa264_b.jpg',
  '/imagebackgrounds/gray/16257739568_08ab0d4b98_b.jpg',
  '/imagebackgrounds/gray/16257970430_2db7dae831_b.jpg',
  '/imagebackgrounds/gray/8724415943_0d9542f663_b.jpg',
  '/imagebackgrounds/gray/8724415133_3995bdc18e_b.jpg',
  '/imagebackgrounds/gray/8120017099_d3dc007b74_b.jpg',
  '/imagebackgrounds/gray/29650892815_104411dc55_b.jpg',
  '/imagebackgrounds/gray/8120016780_89f4bb09c7_b.jpg',
  '/imagebackgrounds/gray/8419720760_f68645f2bf_b.jpg',
  '/imagebackgrounds/gray/8486935872_75aec0b000_b.jpg',
];

function randomSmallPhotoStreamOffset() {
  return 2 + Math.floor(0.3 * (photosFromStream.length - 3) * Math.random());
}

const BleachEffect = styled.div`
  transform: translate3d(0, 0, 0); /* Potentially still useful hack to force enable GPU-acceleration */
  position: absolute;
  width: 100vw;
  height: 100vh;

  & img {
    transform: translate3d(0, 0, 0); /* Potentially still useful hack to force enable GPU-acceleration */
    will-change: opacity;
    transition: opacity 2s;
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .first,
  .first.show-first {
    opacity: 0.2;
  }
  .first.show-second {
    opacity: 0;
  }
  .second.show-first {
    opacity: 0;
  }
  .second,
  .second.show-second {
    opacity: 0.2;
  }

  &::before {
    transform: translate3d(0, 0, 0); /* Potentially still useful hack to force enable GPU-acceleration */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // console.log('Preloaded!', src);
      resolve(img);
    };
    img.onerror = img.onabort = () => {
      reject(src);
    };
    img.src = src;
    // console.log('Preloading', src);
  });
}

const tickValueClass = ['show-second', 'show-first', 'show-first', 'show-second'];

const enableDebug = false;

export function ImageBackground() {
  const nextImageRef = React.useRef('');
  const [tickValue, nextTick] = React.useReducer(state => ++state % tickValueClass.length, 1);
  const [, setupNextImage] = React.useReducer(lastN => {
    const n = (lastN + randomSmallPhotoStreamOffset()) % photosFromStream.length;
    nextImageRef.current = photosFromStream[n];
    return n;
  }, randomSmallPhotoStreamOffset());
  const [firstSrc, setFirstSrc] = React.useState('');
  const [secondSrc, setSecondSrc] = React.useState('');

  React.useEffect(() => {
    setupNextImage();
    const elts = [...document.getElementsByClassName(enableDebug && 'debug')];
    const starts = elts.map(elt =>
      elt.addEventListener('transitionstart', event => {
        console.log('transition start', event.target.className);
      })
    );
    const ends = elts.map(elt =>
      elt.addEventListener('transitionend', event => {
        console.log('transition end', event.target.className);
      })
    );
    return () => {
      starts?.map(unsub => unsub());
      ends?.map(unsub => unsub());
    };
  }, []);

  React.useEffect(() => {
    // This effect is self-driving:
    //  calling nextTick happens in some way in all cases, and the tickValue change re-triggers the effect
    const src = nextImageRef.current;
    // console.log('tickValue', tickValue, src.slice(-9));
    switch (tickValue) {
      case 1:
        setFirstSrc(src);
        setTimeout(nextTick, config.IMAGE_BACKGROUND_SWAP * 1000);
        break;
      case 3:
        setSecondSrc(src);
        setTimeout(nextTick, config.IMAGE_BACKGROUND_SWAP * 1000);
        break;
      default:
        preloadImage(src)
          .then(setupNextImage)
          .then(() => enableDebug && console.log('next image picked:', nextImageRef.current))
          .then(nextTick);
        break;
    }
  }, [tickValue]);

  const klass = tickValueClass[tickValue];
  return (
    <BleachEffect>
      <img alt="" className={`debug first ${klass}`} src={firstSrc || '/_tretton37_crest_green.png'} />
      <img alt="" className={`debug second ${klass}`} src={secondSrc || '/_tretton37_crest_green.png'} />
    </BleachEffect>
  );
}
