import React from 'react';
import { preparePeopleInOfficeSlide, prepareCountdown, makePreannounceSlides } from '../helpers/contentFeed';
import { KNOWABUNGA_DATE } from '../components/KnowabungaCountdown';
import { useEmployeesInOffice, usePreannounce, useRssPosts } from './use1337Api';

function makeSlides(feedItems, inOffice, preannounce) {
  const extraSlides = makePreannounceSlides(preannounce);
  let extraIndex = 0;
  return (feedItems || []).reduce((acc, current, index) => {
    if (inOffice?.length) {
      if (index % 12 === 0 && extraSlides.length) {
        extraIndex = (extraIndex + 1) % extraSlides.length;
        acc.push(extraSlides[extraIndex]);
      } else {
        acc.push(preparePeopleInOfficeSlide(inOffice, index));
      }
    }
    if (index % 2 === 0 && Date.now() < KNOWABUNGA_DATE) {
      acc.push(prepareCountdown(index));
    }
    acc.push(current);
    return acc;
  }, []);
}

export function useFeed() {
  const feedItems = useRssPosts();
  const inOffice = useEmployeesInOffice();
  const preannounce = usePreannounce();

  // Careful: memo is needed because we dispatch actionTypes.setItems everytime this changes.
  return React.useMemo(() => makeSlides(feedItems, inOffice, preannounce), [feedItems, inOffice, preannounce]);
}
