import React from 'react';
import { useManualQuery } from 'graphql-hooks';

import { useAuthentication } from './useAuthentication';
import { useMqttMessages, messageActions } from './useMqttMessages';
import { parseGraphQLApiErrors } from '../helpers/errorHelpers';

const Context = React.createContext(null);

const fakeDeviceShuffle = {
	currentTrack: "",
	nextTrack: "",
	isShufflePlaybackPaused: true,
	isShufflePlaybackMuted: true,
	setIsShufflePlaybackPaused: () => null,
	setIsShufflePlaybackMuted: () => null,
	playNextTrack: () => null,
	playPreviousTrack: () => null,
	fetchDeviceShuffleInProgress: false,
	fetchDeviceShuffleErrors: false,
}

export function DeviceShuffleProvider({ children }) {
  // const data = useDeviceShuffleProvider();
  return <Context.Provider value={fakeDeviceShuffle}>{children}</Context.Provider>;
}

export function useDeviceShuffle() {
  return React.useContext(Context);
}

const DEVICE_SHUFFLE_QUERY = `query Device($deviceId: String!) {
  device(id: $deviceId) {
    shuffle {
      id
      title
      artist
    }
  }
}`;

const actions = {
  resetDeviceShuffle: 'resetDeviceShuffle',
  triggerDeviceShuffleRefetch: 'triggerDeviceShuffleRefetch',
  fetchDeviceShuffle: 'fetchDeviceShuffle',
  handleFetchDeviceShuffleSuccess: 'handleFetchDeviceShuffleSuccess',
  handleFetchDeviceShuffleErrors: 'handleFetchActiveDeviceErrors',
  setCurrentAndNextTracks: 'setCurrentAndNextTracks',
  setIsPlaybackPaused: 'setIsPlaybackPaused',
  togglePlayback: 'togglePlayback',
  setIsPlaybackMuted: 'setIsPlaybackMuted',
  playNextTrack: 'playNextTrack ',
  playPreviousTrack: 'playPreviousTrack ',
  playSelectedTrack: 'playSelectedTrack',
  insertTrackIntoShuffle: 'insertTrackIntoShuffle',
  updateTrackInShuffle: 'updateTrackInShuffle',
  removeTrackFromShuffle: 'removeTrackFromShuffle',
};

const initialState = {
  deviceShuffle: [],
  currentTrackIndex: 0,
  currentTrack: null,
  nextTrack: null,
  isPlaybackPaused: false,
  isPlaybackMuted: false,
  fetchInProgress: false,
  fetchErrors: [],
};

function deviceShuffleReducer(state, action) {
  switch (action.type) {
    case actions.resetDeviceShuffle:
      return { ...initialState };
    case actions.triggerDeviceShuffleRefetch:
      return { ...state, deviceShuffleRefetchTrigger: !state.deviceShuffleRefetchTrigger };
    case actions.fetchDeviceShuffle:
      return { ...state, fetchInProgress: true, fetchErrors: [] };
    case actions.handleFetchDeviceShuffleSuccess:
      return {
        ...state,
        deviceShuffle: compositeNewDeviceShuffle(action.shuffle, state.currentTrack, state.nextTrack),
        currentTrackIndex: 0,
        fetchInProgress: false,
        fetchErrors: [],
      };
    case actions.handleFetchDeviceShuffleErrors:
      return { ...initialState, fetchErrors: action.errors };
    case actions.setCurrentAndNextTracks:
      return { ...state, currentTrack: action.tracks.current, nextTrack: action.tracks.next };
    case actions.setIsPlaybackPaused:
      return { ...state, isPlaybackPaused: action.isPlaybackPaused };
    case actions.togglePlayback:
      return { ...state, isPlaybackPaused: !state.isPlaybackPaused };
    case actions.setIsPlaybackMuted:
      return { ...state, isPlaybackMuted: action.isPlaybackMuted };
    case actions.playNextTrack: {
      const nextTrackIndex = (state.currentTrackIndex + 1) % (state.deviceShuffle || []).length;
      return { ...state, currentTrackIndex: nextTrackIndex };
    }
    case actions.playPreviousTrack:
      // TODO (jurebajt): How to implement going back through shuffle when a new shuffle has already been fetched?
      // History is lost - it's overwritten by new shuffle.
      return { ...state, currentTrackIndex: Math.max(state.currentTrackIndex - 1, 0) };
    case actions.playSelectedTrack: {
      const [updatedDeviceShuffle, updatedCurrentTrackIndex] = updateShuffleAndCurrentIndexToPlaySelectedTrack(
        state.deviceShuffle,
        action.selectedTrack,
        state.currentTrackIndex
      );
      return {
        ...state,
        deviceShuffle: updatedDeviceShuffle,
        currentTrackIndex: updatedCurrentTrackIndex,
      };
    }
    case actions.insertTrackIntoShuffle:
      return { ...state, deviceShuffle: [...state.deviceShuffle, action.track] };
    case actions.updateTrackInShuffle:
      return {
        ...state,
        deviceShuffle: state.deviceShuffle.map(track => {
          if (track.id === action.track.id) {
            return action.track;
          }
          return track;
        }),
      };
    case actions.removeTrackFromShuffle:
      if (state.currentTrack.id === action.track.id) {
        return state; // Don't remove the track if it's currently playing
      }
      return {
        ...state,
        deviceShuffle: state.deviceShuffle.filter(track => track.id !== action.track.id),
      };
    default:
      return state;
  }
}

function compositeNewDeviceShuffle(newShuffle, currentTrack, nextTrack) {
  let isCurrentTrackStillInShuffle = false;
  let isNextTrackStillInShuffle = false;
  let deviceShuffle = newShuffle.filter(track => {
    const doesTrackMatchCurrentTrack = !!currentTrack && track.id === currentTrack.id;
    if (doesTrackMatchCurrentTrack) {
      isCurrentTrackStillInShuffle = true;
    }
    const doesTrackMatchNextTrack = !!nextTrack && track.id === nextTrack.id;
    if (doesTrackMatchNextTrack) {
      isNextTrackStillInShuffle = true;
    }
    return !doesTrackMatchCurrentTrack && !doesTrackMatchNextTrack;
  });

  if (isNextTrackStillInShuffle) {
    deviceShuffle = [nextTrack, ...deviceShuffle];
  }
  if (isCurrentTrackStillInShuffle) {
    deviceShuffle = [currentTrack, ...deviceShuffle];
  }
  return deviceShuffle;
}

function shouldPrefetchNewDeviceShuffle(shuffleLength, currentTrackIndex) {
  return shuffleLength > 2 && currentTrackIndex + 2 >= shuffleLength;
}

function getCurrentAndNextTracks(deviceShuffle, currentTrackIndex) {
  const currentTrack = deviceShuffle[currentTrackIndex] || null;
  let nextTrack = deviceShuffle[(currentTrackIndex + 1) % deviceShuffle.length] || null;
  if (currentTrack === nextTrack) {
    nextTrack = null;
  }
  return { currentTrack, nextTrack };
}

function updateShuffleAndCurrentIndexToPlaySelectedTrack(deviceShuffle, selectedTrack, currentTrackIndex) {
  const selectedTrackIndex = deviceShuffle.findIndex(track => track.id === selectedTrack.id);
  if (selectedTrackIndex === -1) {
    return [deviceShuffle, currentTrackIndex];
  }
  let updatedCurrentTrackIndex = currentTrackIndex;
  if (selectedTrackIndex > currentTrackIndex) {
    updatedCurrentTrackIndex += 1;
  }
  const shuffleWithoutSelectedTrack = [...deviceShuffle];
  const track = shuffleWithoutSelectedTrack.splice(selectedTrackIndex, 1)[0];
  const updatedDeviceShuffle = [
    ...shuffleWithoutSelectedTrack.slice(0, updatedCurrentTrackIndex),
    track,
    ...shuffleWithoutSelectedTrack.slice(updatedCurrentTrackIndex),
  ];
  return [updatedDeviceShuffle, updatedCurrentTrackIndex];
}

function useDeviceShuffleProvider() {
  const { subscribeToMqttMessages } = useMqttMessages();
  const [state, dispatch] = React.useReducer(deviceShuffleReducer, initialState);
  const [fetchDeviceShuffleQuery] = useManualQuery(DEVICE_SHUFFLE_QUERY);
  const { deviceId } = useAuthentication();

  const fetchDeviceShuffle = React.useCallback(
    async deviceId => {
      dispatch({ type: actions.fetchDeviceShuffle });
      const { data, error } = await fetchDeviceShuffleQuery({
        variables: { deviceId },
      });
      if (data && data.device) {
        const deviceShuffle = data.device.shuffle || [];
        dispatch({ type: actions.handleFetchDeviceShuffleSuccess, shuffle: deviceShuffle });
        return deviceShuffle;
      }
      if (error) {
        const errors = parseGraphQLApiErrors(error);
        dispatch({ type: actions.handleFetchDeviceShuffleErrors, errors });
        throw errors;
      }
    },
    [fetchDeviceShuffleQuery]
  );

  function setIsShufflePlaybackPaused(isPlaybackPaused) {
    dispatch({ type: actions.setIsPlaybackPaused, isPlaybackPaused });
  }

  function toggleShufflePlayback() {
    dispatch({ type: actions.togglePlayback });
  }

  function setIsShufflePlaybackMuted(isPlaybackMuted) {
    dispatch({ type: actions.setIsPlaybackMuted, isPlaybackMuted });
  }

  function playNextTrack() {
    dispatch({ type: actions.playNextTrack });
  }

  function playPreviousTrack() {
    dispatch({ type: actions.playPreviousTrack });
  }

  function playSelectedTrack(selectedTrack) {
    dispatch({ type: actions.playSelectedTrack, selectedTrack });
  }

  function updateShuffle(updatedShuffle) {
    dispatch({ type: actions.handleFetchDeviceShuffleSuccess, shuffle: updatedShuffle });
  }

  function insertTrackIntoShuffle(track) {
    if (track) {
      dispatch({ type: actions.insertTrackIntoShuffle, track });
    }
  }

  function updateTrackInShuffle(track) {
    if (track) {
      dispatch({ type: actions.updateTrackInShuffle, track });
    }
  }

  function removeTrackFromShuffle(track) {
    if (track) {
      dispatch({ type: actions.removeTrackFromShuffle, track });
    }
  }

  React.useEffect(() => {
    if (!deviceId) {
      dispatch({ type: actions.resetDeviceShuffle });
    } else {
      fetchDeviceShuffle(deviceId);
    }
  }, [deviceId, state.deviceShuffleRefetchTrigger, fetchDeviceShuffle]);

  React.useEffect(() => {
    const deviceShuffle = state.deviceShuffle || [];
    if (deviceShuffle.length === 0) {
      dispatch({ type: actions.resetDeviceShuffle });
      return;
    }
    if (shouldPrefetchNewDeviceShuffle(deviceShuffle.length, state.currentTrackIndex)) {
      dispatch({ type: actions.triggerDeviceShuffleRefetch });
    }
    const { currentTrack, nextTrack } = getCurrentAndNextTracks(deviceShuffle, state.currentTrackIndex);
    dispatch({
      type: actions.setCurrentAndNextTracks,
      tracks: {
        current: currentTrack,
        next: nextTrack,
      },
    });
  }, [state.deviceShuffle, state.currentTrackIndex]);

  React.useEffect(() => {
    const unsubToggleShufflePlaybackSubscription = subscribeToMqttMessages(
      messageActions.ToggleShufflePlayback,
      toggleShufflePlayback
    );
    const unsubPlayNextTrackSubscription = subscribeToMqttMessages(messageActions.PlayNextTrack, playNextTrack);
    const unsubPlayPreviousTrackSubscription = subscribeToMqttMessages(
      messageActions.PlayPreviousTrack,
      playPreviousTrack
    );
    const unsubPlaySelectedTrackSubscription = subscribeToMqttMessages(
      messageActions.PlaySelectedTrack,
      playSelectedTrack
    );
    const unsubShuffleUpdateSubscription = subscribeToMqttMessages(messageActions.ShuffleUpdate, updateShuffle);
    const unsubShuffleTrackInsertSubscription = subscribeToMqttMessages(
      messageActions.ShuffleTrackInsert,
      insertTrackIntoShuffle
    );
    const unsubShuffleTrackUpdateSubscription = subscribeToMqttMessages(
      messageActions.ShuffleTrackUpdate,
      updateTrackInShuffle
    );
    const unsubShuffleTrackDeleteSubscription = subscribeToMqttMessages(
      messageActions.ShuffleTrackDelete,
      removeTrackFromShuffle
    );

    return () => {
      unsubToggleShufflePlaybackSubscription();
      unsubPlayNextTrackSubscription();
      unsubPlayPreviousTrackSubscription();
      unsubPlaySelectedTrackSubscription();
      unsubShuffleUpdateSubscription();
      unsubShuffleTrackInsertSubscription();
      unsubShuffleTrackUpdateSubscription();
      unsubShuffleTrackDeleteSubscription();
    };
    // skipped in dependency array below: subscribeToMqttMessages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    currentTrack: state.currentTrack,
    nextTrack: state.nextTrack,
    isShufflePlaybackPaused: state.isPlaybackPaused,
    isShufflePlaybackMuted: state.isPlaybackMuted,
    setIsShufflePlaybackPaused,
    setIsShufflePlaybackMuted,
    playNextTrack,
    playPreviousTrack,
    fetchDeviceShuffleInProgress: state.fetchInProgress,
    fetchDeviceShuffleErrors: state.fetchErrors,
  };
}
