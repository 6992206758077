import styled from 'styled-components';

export const CategoryItemRow = styled.div`
  margin: auto;
  flex-direction: row;
  display: flex;
`;

export const PreannounceColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
`;

export const CategoryItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Sora;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
  white-space: nowrap;
  color: #0c0c91;
`;

export const CategoryActionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Sora;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
  white-space: nowrap;
  color: #0c0c91;
  justify-content: space-between;
`;

export const CategoryItemsList = styled.div`
  flex-grow: 1;
  max-height: 60vh;

  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(9, auto);
  column-gap: 2.5rem;
`;

export const Title = styled.h1`
  padding: 8px 10px 6px 10px;
  text-align: center;
  width: 100%;

  font-family: Sora;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  letter-spacing: -0.25px;
  text-transform: uppercase;
  white-space: nowrap;

  color: #05e273;
  background: #0c0c91;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
